import React from 'react';
import './Main-css.css';

class Name extends React.Component {
    render() {
        return (
            <div className="section-container" id="name">
                <div className="name-container">
                    <div className="name-title">
                        <h1>Liu Min</h1>
                        <p>Resume</p>
                    </div>
                </div>
            </div>
        )
    }
}

export default Name;