import React from 'react';
import './Main-css.css';

import { RiVipCrown2Line} from "react-icons/ri";

const skills = {
    'Javascript': 2,
    'NodeJS': 1,
    'ReactJS': 1,
    'REST APIs': 2,
    'Ruby': 2,
    'Ruby on Rails': 2,
    'SQL': 2,
    'VueJS': 1
}

const languages = {
    'Chinese (Mandarin)': 3,
    'English': 3,
    'Japanese': 2
}

const tools = {
    'Datadog': 1,
    'Git': 2,
    'MS Office': 3
}

const skillLevel = (number) => {
    if (number === 1) {
        return <span><RiVipCrown2Line /><RiVipCrown2Line id="skill-logo-null"/><RiVipCrown2Line id="skill-logo-null"/></span>
    } else if (number === 2) {
        return <span><RiVipCrown2Line /><RiVipCrown2Line /><RiVipCrown2Line id="skill-logo-null"/></span>
    } else if (number === 3) {
        return <span><RiVipCrown2Line /><RiVipCrown2Line /><RiVipCrown2Line /></span>
    }
}

class Abilities extends React.Component {
    render() {
        return (
            <div className="section-container" id="abilities">
                <div className="section-title">
                    Abilities
                </div>
                <div className="skills-container">
                    <div className="skill-group">
                        <div className="skill-subtitle">Skills</div>
                        {Object.keys(skills).map(key => {
                            return (
                                <div className="skill-row">
                                    <div className="skill-name">{key}</div>
                                    <div className="skill-level">{skillLevel(skills[key])}</div>
                                </div>
                            )
                        })}
                    </div>
                    <div className="skill-divider" />
                    <div className="skill-group">
                        <div className="skill-subtitle">Languages</div>
                        {Object.keys(languages).map(key => {
                            return (
                                <div className="skill-row">
                                    <div className="skill-name">{key}</div>
                                    <div className="skill-level">{skillLevel(languages[key])}</div>
                                </div>
                            )
                        })}
                    </div>
                    <div className="skill-divider" />
                    <div className="skill-group">
                        <div className="skill-subtitle">Tools</div>
                        {Object.keys(tools).map(key => {
                            return (
                                <div className="skill-row">
                                    <div className="skill-name">{key}</div>
                                    <div className="skill-level">{skillLevel(tools[key])}</div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
        )
    }
}

export default Abilities;
