import React from 'react';
import './Main-css.css';

import Navibar from './Navibar';
import Name from './Name';
import Profile from './Profile';
import Experience from './Experience';
import Abilities from './Abilities';
import Projects from './Projects';

class App extends React.Component {
  render() {
    return (
      <div>
        <Navibar />
        <Name 
          title="name"
        />
        <Profile 
          title="profile"
        />
        <Experience 
          title="experience"
        />
        <Abilities 
          title="abilities"
        />
        <Projects 
          title="projects"
        />
      </div>
    );
  }
}

export default App;
