import React from 'react';
import './Main-css.css';

const education = [
    {
        'period': 'Jun 2020 - Sep 2020',
        'course': 'Web Development Course',
        'school': 'Codecademy'
    },
    {
        'period': 'Aug 2011 - May 2013',
        'course': 'Bachelor of Accountancy with Honors',
        'school': 'Nanyang Technological University'
    },
]

const career = [
    {
        'period': 'Aug 2022 - current',
        'title': 'Junior Software Engineer',
        'company': 'CoverWallet Inc',
        'description': 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'
    },
    {
        'period': 'Oct 2020 - Aug 2022',
        'title': 'Software Developer',
        'company': 'Payboy Pte Ltd',
        'description': 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'
    },
    {
        'period': 'Mar 2019 - Jun 2020',
        'title': 'Senior Accounting Executive',
        'company': 'Mitsui Fudosan Asia',
        'description':  'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'
    },
    {
        'period': 'Jan 2017 - Feb 2019',
        'title': 'Accountant',
        'company': 'Plus65 Interactive',
        'description':  'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'
    },
    {
        'period': 'Sep 2013 - Mar 2015',
        'title': 'Audit Senior',
        'company': 'RSM Chio Lim',
        'description':  'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'
    }
]

class Experience extends React.Component {
    render() {
        return (
            <div className="section-container" id="experience">
                <div className="section-title">
                    Experience
                </div>
                <div className="experience-container">
                    <div className="experience-group">
                        <div className="experience-subtitle">
                            Education
                        </div>
                        {education.map(education => {
                            return (
                                <div className="experience-row">
                                    <div className="experience-period">{education.period}</div>
                                    <div className="experience-details">
                                        {education.course}
                                        <p>{education.school}</p>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                    <div className="experience-group">
                        <div className="experience-subtitle">
                            Career
                        </div>
                        {career.map(career => {
                            return (
                                <div className="experience-row">
                                    <div className="experience-period">{career.period}</div>
                                    <div className="experience-details">
                                        {career.title}
                                        <p>{career.company}</p>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
        )
    }
}

export default Experience;
