import React from 'react';
import './Main-css.css';

import { ExternalLink } from 'react-external-link';
import recipes from './media/recipes-screenshot.png';
import cheatsheet from './media/cheatsheet-screenshot.png'

const projects = [
    {
        'title': 'Recipes',
        'image': recipes,
        'description': 'A personal recipe book which supports adding, editing, and searching of recipes',
        'tech': ['NodeJS', 'React', 'SQL'],
        'url': '#'
    },
    {
        'title': 'Cheatsheet',
        'image': cheatsheet,
        'description': 'An interactive cheatsheet for web development',
        'tech': ['CSS', 'React'],
        'url': 'https://cheatsheet.liumin.dev'
    }
]

class Projects extends React.Component {
    render() {
        return (
            <div className="section-container" id="projects">
                <div className="section-title">
                    Projects
                </div>
                <div className="projects-container">
                    {projects.map(project => {
                        return (
                            <ExternalLink href={project.url}>
                                <div className="project">
                                    <div className="project-image">
                                        <img src={project.image} alt={project.title} />
                                    </div>
                                    <div className="project-details">
                                        <div className="project-details-title">{project.title}</div>
                                        <div className="project-divider" />
                                        <div className="project-details-body">{project.description}</div>
                                        <div className="project-details-body">{project.tech.map(tech => {
                                            return `#${tech} `
                                        })}</div>
                                    </div>
                                </div>
                            </ExternalLink>
                        )
                    })}

                </div>
            </div>
        )
    }
}

export default Projects;
