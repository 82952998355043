import React from 'react';
import './Main-css.css';

import { RiLinkedinBoxFill, RiGithubFill, RiMapPin2Fill } from "react-icons/ri";
import { ExternalLink } from 'react-external-link';

class Profile extends React.Component {
    render() {
        return (
            <div className="section-container" id="profile">
                <div className="section-title">
                    About Me
                </div>
                <div className="profile-container">
                    <div className="profile-details">
                        <p>Name: Liu Min</p>
                        <p><RiMapPin2Fill />&nbsp;Singapore</p>
                        <p id="profile-logos">
                            <ExternalLink href="https://www.linkedin.com/in/liuminish/"><RiLinkedinBoxFill /></ExternalLink>
                            <ExternalLink href="https://github.com/liuminish"><RiGithubFill /></ExternalLink>
                        </p>
                    </div>
                    <div className="profile-about-me">
                        I'm a backend developer whose experience revolves mainly around Ruby on Rails.
                        I recognise that there's still much for me to learn about this ever-advancing world of Tech and am excited about the prospect of always having something new to learn.
                        <p>
                            The few words to describe me are: motivated, responsible and reliable.
                        </p>
                        <p>
                            My other interests include: self enrichment, music and dance.
                        </p>
                    </div>
                </div>
            </div>
        )
    }
}

export default Profile;
