import React from 'react';
import './Main-css.css';

import { Link } from "react-scroll";
import { ExternalLink } from 'react-external-link';
import { RiArrowUpSLine, RiArrowDownSLine } from "react-icons/ri";

class Navibar extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            nextElement: 'profile',
            isNavibarGrey: false
        }

        this.setNextElement = this.setNextElement.bind(this);
        this.atTop = this.atTop.bind(this);
    }

    setNextElement(element) {
        this.setState({
            nextElement: element,
            isNavibarGrey: true
        })
    }

    atTop() {
        this.setState({
            nextElement: 'profile',
            isNavibarGrey: false
        })
    }

    render() {
        const navibarStyle = this.state.isNavibarGrey ? 'navibar-container-grey' : 'navibar-container-black';
        return (
            <div className={navibarStyle}>
                    <Link activeClass="active-disappear" to="name" spy={true} smooth={true} offset={0} duration={500} onSetActive={this.atTop}>
                        <div className="navibar-item" id="arrow-logo"><RiArrowUpSLine /></div>
                    </Link>
                    <Link activeClass="active" to="profile" spy={true} smooth={true} offset={0} duration={500} onSetActive={() => this.setNextElement('experience')}>
                        <div className="navibar-item">Profile</div>
                    </Link>
                    <Link activeClass="active" to="experience" spy={true} smooth={true} offset={0} duration={500} onSetActive={() => this.setNextElement('abilities')}>
                        <div className="navibar-item">Experience</div>
                    </Link>
                    <Link activeClass="active" to="abilities" spy={true} smooth={true} offset={0} duration={500} onSetActive={() => this.setNextElement('projects')}>
                        <div className="navibar-item">Abilities</div>
                    </Link>
                    <Link activeClass="active" to="projects" spy={true} smooth={true} offset={0} duration={500}>
                        <div className="navibar-item">Projects</div>
                    </Link>
                    <ExternalLink href="https://blog.liumin.dev">
                        <div className="navibar-item">Blog</div>
                    </ExternalLink>
                    <Link activeClass="active-disappear" to={this.state.nextElement} spy={true} smooth={true} offset={0} duration={500}>
                        <div className="navibar-item" id="arrow-logo"><RiArrowDownSLine /></div>
                    </Link>
            </div>
        )
    }
}

export default Navibar;
